.Start {
  height: 100vh;
  background-color: #121212;
}

// TEXT

#text {
  text-align: center;
  // border: 1px solid orange;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  h1 {
    z-index: 9999;
    font-size: 48px;
    font-family: serif;
    color: #121212;
    -webkit-text-stroke: 1px #83A8CA;
    margin: 0;
    padding: 0;
  }

  p {
    z-index: 9999;
    font-size: 16px;
    font-family: sans-serif;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
  }
}

// SPINNING

#container {
  margin: 0%;
  width: 50%;
  max-width: 100px;
  height: 50%;
  max-height: 100px;
  // border: 1px solid crimson;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-left: 150px;
  padding-bottom: 80px;
}

#circle {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

#circle text {
  font-family: sans-serif;
  font-size: 16px;
}

#circle svg { position: absolute; left: 0; top: 0; width: 100%; height: 100%;

    -webkit-animation-name: rotate;
      -moz-animation-name: rotate;
        -ms-animation-name: rotate;
        -o-animation-name: rotate;
            animation-name: rotate;
    -webkit-animation-duration: 10s;
      -moz-animation-duration: 10s;
        -ms-animation-duration: 10s;
        -o-animation-duration: 10s;
            animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
        -ms-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
        -ms-animation-timing-function: linear;
        -o-animation-timing-function: linear;
            animation-timing-function: linear;

}

  @-webkit-keyframes rotate {
      from { -webkit-transform: rotate(0); }
      to { -webkit-transform: rotate(360deg); }
  }
  @-moz-keyframes rotate {
      from { -moz-transform: rotate(0); }
      to { -moz-transform: rotate(360deg); }
  }
  @-ms-keyframes rotate {
      from { -ms-transform: rotate(0); }
      to { -ms-transform: rotate(360deg); }
  }
  @-o-keyframes rotate {
      from { -o-transform: rotate(0); }
      to { -o-transform: rotate(360deg); }
  }
  @keyframes rotate {
      from { transform: rotate(0); }
      to { transform: rotate(360deg); }
  }