.Admin {
    height: 100vh;
    text-align: center;

    input {
        width: 100%;
        max-width: 500px;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
    }
}