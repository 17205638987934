.AdminView {

    .AdminView__item {
        width: 100%;
        max-width: 500px;
        padding: 12px 20px;
        margin: 8px auto;
        text-align: left;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        border-radius: 5px;

        .AdminView__itemCol {
            margin: 8px 0;
        }
    }
}